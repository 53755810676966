import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import HostName from '../../data/static/HostNames'
import ReferralBanner from 'images/referal-times-two.png'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/ReferralFeeMoTimesTwo`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'REFERRAL FEE MO TIMES TWO (February 1-28, 2022)',
  },
  {
    property: 'og:description',
    content: 'Get a P100 referral fee when you successfully refer an agent',
  },
  {
    property: 'og:image',
    content: ReferralBanner,
  },
]

const Promo4 = () => (
  <div>
    <Helmet title="Promos | Referral Fee Mo Times Two" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page">
      <h1 align="center" className="font-weight-bold">
        REFERRAL MO TIMES TWO
      </h1>
      <Image
        src={ReferralBanner}
        width="100%"
        align="center"
        className="promo-image"
      />
      <h4>MECHANICS</h4>
      <div>
        <ul>
          <li>
            Get a P100 referral fee when you successfully refer an agent with
            the following requirements:
            <ul>
              <li>Two (2) valid IDs</li>
              <li>Minimum top-up amount of P1,000</li>
            </ul>
          </li>

          <li>
            Get a P200 referral fee when you successfully refer an agent with
            the following requirements:
            <ul>
              <li>Two (2) valid IDs</li>
              <li>SEC or DTI Registration</li>
              <li>Mayor’s or Barangay Permit</li>
              <li>
                Store photos (at least 3 clear photos, including the street
                view)
              </li>
              <li>Minimum top-up amount of P1,000</li>
            </ul>
          </li>
        </ul>
      </div>
      <i>
        Disclaimer: New agents who will sign up through this promotion and will
        provide business documents are not eligible to receive Digipay credits
        under the DigiPadala Conversion promotion
      </i>
      <h4>HOW TO CLAIM THE PRIZE</h4>
      <div>
        <ul>
          <li>
            Digipay Team will credit the referral fee within five (5) days after
            the last day of the promo period
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
)
export default Promo4
